body {
  background: linear-gradient(0.33turn, #000428, #004e92);
  height: 100vh;
  width: 100%;
  margin: 0;
}

p {
  padding: 0px;
  margin: 0px;
  color: white;
  font-family: "Avenir", Georgia, "Times New Roman", Times, serif;
}

#root {
  height: 100%;
}

.Main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Item {
  width: 100%;
}

.FlexBreak {
  flex-basis: 100%;
  height: 0;
}

.BrandLabel {
  font-size: 44px;
  font-weight: bold;
}

.BrandDescriptionLabel {
  font-size: 22px;
  font-weight: 200;
}

.contact-link {
  color: white;
  padding: 5px 0px;
}

@media screen and (max-width: 767.98px) {
  .BrandLabel {
    font-size: 28px;
    font-weight: bold;
  }

  .BrandDescriptionLabel {
    font-size: 16px;
    font-weight: 200;
  }
}
